export function scrollToY(y, speed) {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const scrollTargetY = y || 0;
    const scrollSpeed = speed || 2000;
    const easing = (pos) => { return Math.sin(pos * (Math.PI / 2)); };
    const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / scrollSpeed, 0.8));
    let currentTime = 0;

    function tick() {
        currentTime += 1 / 60;

        var p = currentTime / time;
        var t = easing(p);

        if (p < 1) {
            window.requestAnimationFrame(tick);

            window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
            window.scrollTo(0, scrollTargetY);
        }
    }

    tick();
}