const moduleName = 'header';

export function header() {

    Array.from(document.querySelectorAll('.js-header')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const header = self;
        const headerOpenButton = header.querySelector('.js-header-open-button');
        const headerCloseButtons = header.querySelectorAll('.js-header-close-button');

        headerOpenButton.addEventListener('click', showHeaderMenu);

        Array.from(headerCloseButtons).forEach((headerCloseButton) => {
            headerCloseButton.addEventListener('click', () => {
                if (headerCloseButton.classList.contains('js-popup-open-button')) {
                    hideHeaderMenu(false);
                } else {
                    hideHeaderMenu(true);
                }
            });
        });

        window.addEventListener('click', (event) => {
            if (!event.target.closest('.js-header-menu') && !event.target.closest('.js-header-open-button') && header.classList.contains('_open')) hideHeaderMenu(true);
        });

        window.addEventListener('resize', (event) => {
            if (header.classList.contains('_open')) hideHeaderMenu(true);
        });

        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 27 && header.classList.contains('_open')) hideHeaderMenu(true);
        });



        function showHeaderMenu() {
            header.classList.add('_open');
            overlay().show();
        }

        function hideHeaderMenu(hideOverlay) {
            header.classList.remove('_open');

            if (hideOverlay) overlay().hide();
        }
    });
}