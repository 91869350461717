export function overlay() {

    const html = document.documentElement;
    const body = document.body;
    let overlay = document.querySelector('.overlay');

    function showOverlay() {
        addOverlay();

        if (!overlay.classList.contains('_visible')) {
            fixParallaxElements();
            overlay.classList.add('_visible');
            fixViewport();
        }
    }

    function hideOverlay() {
        overlay.classList.remove('_visible');
        unfixViewport();
        setTimeout(unfixParallaxElements, 500);
    }

    function addOverlay() {
        if (overlay) return;

        overlay = document.createElement('div');
        overlay.classList.add('overlay');
        document.body.appendChild(overlay);
    }

    function fixViewport() {
        const scrollY = window.scrollY || document.documentElement.scrollTop;

        if (window.innerWidth > document.documentElement.clientWidth) html.classList.add('has-scrollbar');

        body.style.marginTop = `-${scrollY}px`;
        html.classList.add('fixed');
    }

    function unfixViewport() {
        const newScrollTop = -body.style.marginTop.slice(0, -2);

        html.classList.remove('fixed');
        html.classList.remove('has-scrollbar');
        body.style.marginTop = '';
        window.scrollTo(0, newScrollTop);
    }

    function fixParallaxElements() {
        if (document.body.classList.contains('mobile')) return;

        const parallaxElements = document.querySelectorAll('.js-parallax');

        Array.from(parallaxElements).forEach((parallaxElement, index) => {
            const newParallaxElementId = 'parallax-element-' + index;
            const styleElement = document.createElement('style');
            const parallaxElementStyles = parallaxElement.getAttribute('style');
            const parallaxElementImportantStyles = parallaxElementStyles.replace(/;/g, ' !important;');

            styleElement.innerHTML = `
                #${newParallaxElementId} {
                    ${parallaxElementImportantStyles}
                }
            `;
            styleElement.classList.add('parallax-style');
            parallaxElement.id = newParallaxElementId;
            parallaxElement.parentNode.appendChild(styleElement);
        });
    }

    function unfixParallaxElements() {
        if (document.body.classList.contains('mobile')) return;

        const parallaxStyles = document.querySelectorAll('.parallax-style');

        Array.from(parallaxStyles).forEach((parallaxStyle) => {
            parallaxStyle.parentNode.removeChild(parallaxStyle);
        });
    }

    return {
        show: showOverlay,
        hide: hideOverlay
    };

}