import { scrollToY } from './scrollToY';

const moduleName = 'scrollTo';

export function scrollTo() {

    Array.from(document.querySelectorAll('.js-scroll-to')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        self.addEventListener('click', (event) => {
            const targetSelector = self.dataset.target || self.getAttribute('href') || undefined;
            const target = targetSelector ? document.querySelector(targetSelector) : document.documentElement;
            const y = target.getBoundingClientRect().top + window.scrollY;
            const windowWidth = document.body.clientWidth;
            let yOffset = 0;

            if (windowWidth >= 768 && windowWidth < 1190) {
                yOffset = 90;
            } else if (windowWidth < 768) {
                yOffset = 65;
            }

            scrollToY(y - yOffset);

            event.preventDefault();
        });
    });
}