const moduleName = 'nav';

export function nav() {

    Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const nav = self;
        const navToggle = nav.querySelector('.js-nav-toggle');
        const navDropdownToggles = nav.querySelectorAll('.js-nav-dropdown-toggle');
        let windowWidth = document.body.clientWidth;

        navToggle.addEventListener('click', () => {
            nav.classList.toggle('_open');
        });

        Array.from(navDropdownToggles).forEach((navDropdownToggle) => {
            const navDropdown = navDropdownToggle.closest('.js-nav-dropdown');

            navDropdown.addEventListener('mouseenter', () => {
                if (document.body.clientWidth < 1210) return;

                navDropdown.classList.add('_open');
            });

            navDropdown.addEventListener('mouseleave', () => {
                if (document.body.clientWidth < 1210) return;

                navDropdown.classList.remove('_open');
            });

            navDropdownToggle.addEventListener('click', () => {
                navDropdown.classList.toggle('_open');
            });
        });

        window.addEventListener('resize', () => {
            const openNavDropdowns = nav.querySelectorAll('.js-nav-dropdown._open');
            let newWindowWidth = document.body.clientWidth;

            if (newWindowWidth > 1023 && windowWidth <= 1023) {
                Array.from(openNavDropdowns).forEach((openNavDropdown) => {
                    openNavDropdown.classList.remove('_open');
                });
            }

            windowWidth = newWindowWidth;
        });
    });

}