const moduleName1 = 'popup';
const moduleName2 = 'popupOpenButton';

export function popup() {

    Array.from(document.querySelectorAll('.js-popup')).forEach((self) => {
        if (self.dataset[moduleName1 + 'Init'] === 'true') return;
        self.dataset[moduleName1 + 'Init'] = true;

        const popup = self;
        const popupCloseButtons = popup.querySelectorAll('.js-popup-close-button');

        if (popup.parentNode != document.body) {
            popup.parentNode.removeChild(popup);
            document.body.appendChild(popup);
        }

        Array.from(popupCloseButtons).forEach((popupCloseButton) => {
            popupCloseButton.addEventListener('click', (event) => {
                event.preventDefault();
                closePopup();
            });
        });

        popup.addEventListener('click', (event) => {
            if (!event.target.closest('.js-popup-body')) {
                closePopup();
            }
        });

        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 27 && popup.classList.contains('_active')) {
                closePopup();
            }
        });
    });



    Array.from(document.querySelectorAll('.js-popup-open-button')).forEach((self) => {
        if (self.dataset[moduleName2 + 'Init'] === 'true') return;
        self.dataset[moduleName2 + 'Init'] = true;

        const button = self;
        const popupId = !!button.dataset.popup ? button.dataset.popup : button.getAttribute('href');

        button.addEventListener('click', (event) => {
            openPopup(`${popupId}`);
            event.preventDefault();
        });
    });



    function openPopup(popupId) {
        const popup = document.querySelector(popupId);

        closePopup();

        popup.classList.add('_active');

        setTimeout(() => {
            popup.classList.add('_visible');
            document.activeElement.blur();
        }, 0);

        overlay().show();

        const ytVideo = popup.querySelector('.js-yt-video');

        if (!ytVideo) return;

        let newCustomEvent = new CustomEvent('playYoutubeVideo');

        ytVideo.dispatchEvent(newCustomEvent);
    }

    function closePopup() {
        const activePopup = document.querySelector('.js-popup._active');

        if (!activePopup) return;

        activePopup.classList.remove('_visible');

        setTimeout(() => {
            const successForm = activePopup.querySelector('.js-form._success');

            activePopup.classList.remove('_active');

            if (successForm) successForm.classList.remove('_success');
        }, 500);

        overlay().hide();

        const ytVideo = activePopup.querySelector('.js-yt-video');

        if (!ytVideo) return;

        let newCustomEvent = new CustomEvent('stopYoutubeVideo');

        ytVideo.dispatchEvent(newCustomEvent);
    }



    return {
        open: openPopup,
        close: closePopup
    };

}