const moduleName = 'navbox';

export function navbox() {

    Array.from(document.querySelectorAll('.js-navbox')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const navbox = self;
        const navboxToggle = navbox.querySelector('.js-navbox-toggle');

        navboxToggle.addEventListener('click', () => {
            navbox.classList.toggle('_open');
        });
    });
}