// polyfills
import 'core-js/fn/array/from';
import './modules/polyfills';

// detect mobile devices
import './modules/mobileDetect';

// jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// modules
import { overlay } from './modules/overlay';
import { popup } from './modules/popup';
import { scrollTo } from './modules/scrollTo';
import { tabs } from './modules/tabs';
import { nav } from './modules/nav';
import { header } from './modules/header';
import { navbox } from './modules/navbox';

window.overlay = overlay;
window.popup = popup;
window.initModules = () => {
    scrollTo();
    popup();
    tabs();
    nav();
    header();
    navbox();

    require('./modules/fancybox')();
};

// init modules on page load
if (document.readyState === 'complete') {
    if (!document.body.classList.contains('preload')) {
        initModules();
    } else {
        preload(initModules);
    }
} else if (document.readyState === 'interactive' && !document.body.classList.contains('preload')) {
    initModules();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        if (!document.body.classList.contains('preload')) {
            initModules();
        }
    });

    window.addEventListener('load', () => {
        if (document.body.classList.contains('preload')) {
            preload(initModules);
        }
    });
}